@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400;0,6..96,500;0,6..96,600;0,6..96,700;1,6..96,400;1,6..96,500;1,6..96,600;1,6..96,700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1 {
  font-family: 'Bodoni Moda', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 84px;
  line-height: 110%;
  font-variant: small-caps;
}

h2 {
  font-family: 'Bodoni Moda';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 110%;
  letter-spacing: -0.03em;
}

h3 {
  font-family: 'Bodoni Moda';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 110%;
  letter-spacing: -0.04em;
}

h4 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 44px;
  line-height: 110%;
  font-variant: small-caps;
}

h5 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 110%;
  text-transform: uppercase;
}

h6 {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  text-transform: uppercase;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  -webkit-font-smoothing: antialiased;
}

input, textarea {
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  -webkit-font-smoothing: antialiased;
  border: 1px solid #8E8E8E;
  border-radius: 8px;
  padding: 15px;
  appearance: none;
  resize: none;
  /* Resets browser defaults which vary */
  min-width: 184px;
}

button {
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  -webkit-font-smoothing: antialiased;
  border: 0;
  border-radius: 8px;
  padding: 15px;
  appearance: none;
  cursor: pointer;
  background-color: black;
  color: white;
  text-transform: uppercase;
}

button:hover {
  background-color: #000000b5;
}

/* Reset */
/* https://piccalil.li/blog/a-modern-css-reset/ */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* End reset */

.underline {
  text-decoration: underline;
}